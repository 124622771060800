import React, { useState, useEffect, useRef } from 'react';
import './ImageSection.css';
import Section from '../Section/Section';

function ImageSection({ imageUrl, quote, author, darkText = false }) {
    const [offsetY, setOffsetY] = useState(0);
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const rect = sectionRef.current.getBoundingClientRect();
                const offset = window.scrollY + rect.top; // Distance of the section from the top of the document
                const relativeOffset = window.scrollY - offset; // Relative scroll position
                setOffsetY(relativeOffset);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Section
            ref={sectionRef}
            className="image-section"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: `center calc(${offsetY * 0.5}px)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: 'black',
            }}
        >
            <quote className={darkText ? 'dark' : ''}>{`"${quote}"`}</quote>
            <p className={darkText ? 'dark' : ''}>{author}</p>
        </Section>
    );
}

export default ImageSection;