import React from 'react'
import data from '../../assets/data/blog-data.json'
import BlogPost from '../BlogPost/BlogPost'

function Blog() {

    return (
        <>
            <h2>Ramblings</h2>
            <ul>
                {data.map((item, index) => (
                    <li key={index}>
                        <a href={`#${item.ref}`}>{item.title}</a>
                    </li>
                ))}
            </ul>
            <hr></hr>
            <ul>
                {data.map((item, index) => (
                    <li key={index} id={item.ref}>
                        <BlogPost item={item}/>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Blog
