import React from 'react'
import './BlogPost.css'

function BlogPost({item}) {
    return (
        <div className="blog-post">
            <h3>{item.title}</h3>
            <p className="date">{item.date}</p>
            {item.text.map((textItem, textIndex) => {
                if (textItem.substring(0, 4) === "text") {
                    return <p key={textIndex}>{textItem.substring(4)}</p>;
                } else if (textItem.substring(0, 4) === "code") {
                    return <pre key={textIndex}>{textItem.substring(4)}</pre>;
                } else if (textItem.substring(0, 4) === "head") {
                    return <h4 key={textIndex}>{textItem.substring(4)}</h4>;
                }
                return null; // Render nothing for other cases
            })}
            <hr/>
        </div>
    )
}

export default BlogPost
